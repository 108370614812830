.react-checkbox-tree{
	overflow-x: scroll
}
.rct-checkbox{
    display: none;
}

/*current asset highlight*/
.current-asset{
	background-color: lightblue;
	border-radius: 5px;
}

/* Chevron for hierarchy */
span.chevron{
    font-size: large;
    color: black;
	margin-top: .5em;
}
span.chevron::before {
	border-style: solid;
	border-width: 0.15em 0.15em 0 0;
	content: '';
	display: inline-block;
	height: 0.45em;
	left: 0.15em;
	position: relative;
	top: 0.15em;
	transform: rotate(-45deg);
	vertical-align: top;
	width: 0.45em;
	border-color: gray;
}

span.chevron.right:before {
	left: 0;
	transform: rotate(45deg);
}
span.chevron.bottom:before {
	top: 0;
	transform: rotate(135deg);
}

/*Search Loading*/
.loader {
	border: 16px solid #f3f3f3; /* Light grey */
	border-top: 16px solid #3498db; /* Blue */
	border-radius: 50%;
	width: 80px;
	height: 80px;
	animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
  }