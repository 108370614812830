@media (max-width: 500px) {
    .detail-description > div > div:nth-child(2) {
        max-width: 15.5rem;
        word-break: break-all;
    }
}
div.detail-title{
    margin-left: -25px;
   
}
div.detail-list{
    margin-bottom: -25px;
}