.asset-tabs, .asset-tabs div {
    max-height: 44px;
}
.table-custom, .th-custom, .td-custom {
    border: 1px solid black;
    text-align: center;
}
.table-custom{
    width: 100%;
}
