.App {
  text-align: center;
}

input {
  border-radius: 0;
}

input[type="search"] {
  -webkit-appearance: none;
}

div.save-cancel-menu{
  max-height: 44px;
}